<template>
  <div class="pl-1 pr-1">
    <b-row class="mt-1 mb-1 border rounded p-1">
      <b-col md="12">
        <h4>Filtro de viviendas para el propietario</h4>
      </b-col>

      <b-col md="4">
        <validation-provider name="resort" rules="">
          <b-form-group slot-scope="{ valid, errors }" label="Resort">
            <b-form-select
              :state="errors[0] || !valid ? false : null"
              v-model="selectedResort"
              :clearable="false"
            >
              <option :value="null" disabled>Seleccione una opción</option>
              <option v-for="h in hotels" :key="h.id" :value="h.id">
                {{ h.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="4">
        <validation-provider name="vivienda" rules="">
          <b-form-group slot-scope="{ valid, errors }" label="Vivienda" >
            <b-form-input
              type="number"
              :state="errors[0] || !valid ? false : null"
              v-model="selectedVivienda"
              placeholder="Inserte una vivienda"
            />
            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="4" v-if="!isLoadingHousing">
        <div class="d-flex justify-content-start">
          <b-form-group label="Acciones">
            <b-button variant="warning" @click="resetHousing" class="mr-1" :disabled="!newHousingList.length > 0 || isLoadingSomething">Reset </b-button>
            <b-button variant="primary" @click="searchByFilter" :disabled="isLoadingSomething" >Buscar </b-button>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="12" class="mt-2" v-if="newHousingList.length && !isLoadingHousing">
        <b-input
          :disabled="!canEditOwnerForm"
          v-model="filterInfo"
          placeholder="Filtros para la tabla.."
        />

        <ValidationObserver ref="newHousingListForm" :disabled="true" >
          <b-table
            v-if="newHousingList.length"
            responsive
            sticky-header="400px"
            :filter="filterInfo"
            :items="newHousingList"
            :fields="housingAvailables"
            :filter-included-fields="filterOn"
            class="mb-0 mt-2 my-table"
            show-empty
            empty-text="No hay registros para mostrar"
          >
            <!-- Optional default data cell scoped slot -->
            <template #cell(percentage)="data">
              <ValidationProvider :name="`porcentaje vivienda ${(data.index+1)}`" :rules="`required_if:add_vivienda_${(data.index+1)},true|max_value:100`">
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control"
                    type="number"
                    :disabled="!canEditOwnerForm"
                    :state="errors[0] || !valid ? false : null"
                    v-model="data.item.percent"
                  />
                  <b-form-invalid-feedback class="replacePercent"> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(housingType)="data">
              <ValidationProvider :name="`Tipo vivienda ${(data.index+1)}`" :rules="`required_if:add_vivienda_${(data.index+1)},true`">
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-select
                    :disabled="!canEditOwnerForm"
                    :state="errors[0] || !valid ? false : null"
                    v-model="data.item.relation"
                  >
                    <option :value="null">Seleccione Tipo</option>
                    <option
                      v-for="idHousingList in idHousing"
                      :key="idHousingList.id"
                      :value="idHousingList.id"
                    > {{ idHousingList.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback class="replaceType"> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(contract)="row">
              <div>
                <label v-if="!!row.item.contract || !!row.item.membershipcode">
                  <span class="font-weight-bold">{{ row.item.contract || '-'}}</span> <br>
                  <span class="font-weight-bold">{{ row.item.membershipcode || '-'}}</span>
                </label>
                <span v-else>-</span>
              </div>
            </template>

            <template #cell(status)="data">
              <div>
                <ValidationProvider :name="`add_vivienda_${(data.index+1)}`">
                  <b-form-checkbox
                    :disabled="!canEditOwnerForm"
                    v-model="data.item.state"
                    :value="true"
                    switch
                    inline
                    class="custom-control-success"
                  />
                </ValidationProvider>
              </div>
            </template>
          </b-table>

        </ValidationObserver>
      </b-col>

      <b-col md="12" v-if="isLoadingHousing">
        <div class="text-center mt-2" >
          <b-spinner style="width: 3rem; height: 3rem;" /><br>
          <strong>Cargando</strong>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-1 mb-1 border rounded p-1" v-if="!isDeletingHouseOwner">
      <b-col md="12">
        <h4>Viviendas actuales</h4>
      </b-col>
      <b-col md="12">
        <b-table
          responsive
          :filter="filter"
          :items="housingList"
          :fields="ownerHousingFields"
          class="mb-0"
          show-empty
          :busy.sync="isLoadingOwnerHousing"
          sticky-header="300px"
          empty-text="No hay viviendas asignadas"
        >

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>Cargando viviendas
            </div>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(percentage)="row">
            <ValidationProvider name="porcentaje">
              <b-form-group class="custom-input-margin">
                <b-form-input
                  step="0.01"
                  min="0.00"
                  max="1000.00"
                  class="form-control"
                  type="number"
                  :disabled="!canEditOwnerForm"
                  v-model="row.item.percentage"
                />
              </b-form-group>
            </ValidationProvider>
          </template>

          <template #cell(dateStart)="row">
            <ValidationProvider name="fecha inicio">
              <b-form-group class="custom-input-margin">
                <b-form-input
                  disabled
                  class="form-control"
                  type="date"
                  v-model="row.item.dateIn"
                />
              </b-form-group>
            </ValidationProvider>
          </template>

          <template #cell(dateOut)="row">
            <ValidationProvider name="fecha final">
              <b-form-group class="custom-input-margin">
                <b-form-input
                  disabled
                  class="form-control"
                  type="date"
                  v-model="row.item.dateOut"
                />
              </b-form-group>
            </ValidationProvider>
          </template>

          <template #cell(housingType)="row">
            <ValidationProvider name="Tipo">
              <b-form-group class="custom-input-margin">
                <b-form-select :disabled="!canEditOwnerForm" v-model="row.item.relation">
                  <option value="">Seleccione Tipo</option>
                  <option
                    v-for="idHousingList in idHousing"
                    :key="idHousingList.id"
                    :value="idHousingList.id"
                  > {{ idHousingList.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </template>

          <template #cell(isCurrentOwner)="row">
            <div class="text-center">
              <b-form-checkbox
                :disabled="!canEditOwnerForm"
                v-model="row.item.currentOwner"
                :value="true"
                class="custom-control-success"
              />
            </div>
          </template>

          <template #cell(actions)="row">
            <div>
              <b-button
                v-if="canEditOwnerForm"
                size="sm"
                @click="deleteHouse(row.item)"
                variant="danger"
              > <feather-icon icon="Trash2Icon" size="12" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row v-if="isDeletingHouseOwner">
      <b-col md="12">
        <div class="text-center mt-2" >
          <b-spinner style="width: 3rem; height: 3rem;" /><br>
          <strong>Borrando vivienda</strong>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    isDeletingHouseOwner: {
      type: Boolean,
      required: true,
    },
    isLoadingHousing: {
      type: Boolean,
      required: true,
    },
    isLoadingOwnerHousing: {
      type: Boolean,
      required: true,
    },
    idHousing: {
      type: Array,
      required: true,
    },
    hotels: {
      type: Array,
      required: true
    },
    housingList: {
      type: Array,
      required: true,
    },
    newHousingList: {
      type: Array,
      required: true,
    },
    canEditOwnerForm: {
      type: Boolean,
      required: true,
    },
    isLoadingSomething: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return{
      filter: "",
      filterOn: ["fullname"],
      filterInfo: "",
      selectedResort: null,
      selectedVivienda: null,
      ownerHousingFields: [
        { key: "contract", label: "Contrato", class: "text-center", formatter: value => {
          return !!value ? value : 'N/A'
        }},
        { key: "fullname", label: "Nombre", class: "text-center" },
        { key: "percentage", label: "Porcentaje", class: "text-center" },
        { key: "dateStart", label: "Fecha Inicio", class: "text-center" },
        { key: "dateOut", label: "Fecha Fin", class: "text-center" },
        { key: "housingType", label: "Tipo de Propietario", class: "text-center" },
        { key: "actions", label: "Borrar", class: "text-center" },

      ],
      housingAvailables: [
        { key: "ownername", label: "Propietario", class: "text-center font-weight-bold font-small" },
        { key: "contract", label: "Contrato", class: "text-center" },
        { key: "fullname", label: "Nombre", class: "text-center", thClass: "w-25" },
        { key: "percentage", label: "Porcentaje", class: "text-center", thClass: "w-25" },
        { key: "housingType", label: "Tipo", class: "text-center", thClass: "w-25" },
        { key: "status", label: "Agregar a lista", class: "text-center" },
      ],
    }
  },
  methods: {
    deleteHouse(item){
      this.$emit('delete-house', item)
    },
    resetHousing(){
      this.selectedResort = null
      this.selectedVivienda = null
      this.$emit('reset-housing')
    },
    searchByFilter(){
      this.$emit('search-by-filter', {selectedResort: this.selectedResort, selectedVivienda: this.selectedVivienda})
    },
  }
}
</script>

<style>
.custom-margin{
  margin: 0.5rem 0;
}
.font-small{
  font-size: 0.85rem;
}
.replacePercent{
  visibility: hidden;
  position: relative;
}
.replacePercent:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "El campo Porcentaje es obligatorio.";
}
.replaceType{
  visibility: hidden;
  position: relative;
}
.replaceType:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "El campo Tipo es obligatorio.";
}
</style>