<template>
  <b-card v-if="!isLoadingOwner && originalSelectedOwner">
    <div class="text-right">
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
    </div>
    <b-overlay :show="isLoadingOwner || isUpdatingOwner" rounded class="">
      <ValidationObserver v-slot="{ invalid }" v-if="selectedOwner" >
        <b-form autocomplete="off">
          <b-tabs v-model="tabIndex">
            <b-tab title="General" class="p-1">
              <b-row class="">
                <b-col md="">
                  <ValidationProvider name="código" rules="">
                    <b-form-group label="Código*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="true"
                        v-model="selectedOwner.code"
                        :state="errors[0] ? false : valid ? true : selectedOwner.code ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider name="prefix" rules="required">
                    <b-form-group label="Prefijo*" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : selectedOwner.prefix ? true : false"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.prefix"
                      >
                        <option disabled :value="null">Seleccione prefijo</option>
                        <option
                          v-for="namesPrefixIdList in namesPrefixId"
                          :key="namesPrefixIdList.id"
                          :value="namesPrefixIdList.id"
                        > {{ namesPrefixIdList.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="nombre" rules="required">
                    <b-form-group label="Nombre*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.name"
                        :state="errors[0] ? false : valid ? true : selectedOwner.name ? true : false"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="apellido" rules="required">
                    <b-form-group label="Apellido*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.lastname"
                        :state="errors[0] ? false : valid ? true : selectedOwner.lastname ? true : false"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="Fecha de Nacimiento" rules="">
                    <b-form-group label="Fecha de Nacimiento" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="date"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.birthdate"
                        :state="errors[0] ? false : valid ? true : selectedOwner.birthdate ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="género" rules="required">
                    <b-form-group label="Género*" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : selectedOwner.gender ? true : false"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.gender"
                      >
                        <option disabled value="">Seleccione género</option>
                        <option
                          v-for="gendersList in namesGender"
                          :key="gendersList.id"
                          :value="gendersList.id"
                        > {{ gendersList.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="teléfono" rules="required">
                    <b-form-group label="Teléfono*" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="number"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="15"
                        autocomplete="off"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.phone"
                        :state="errors[0] ? false : valid ? true : selectedOwner.phone ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="correo" rules="required">
                    <b-form-group
                      label="Correo electrónico*"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-input
                        class="form-control"
                        type="email"
                        autocomplete="off"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.email"
                        @change="checkIfEmailClientExist(selectedOwner.email)"
                        :state="errors[0] ? false : valid ? true : selectedOwner.email ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="6">
                      <ValidationProvider name="idioma" rules="required">
                        <b-form-group label="Idioma*" slot-scope="{ valid, errors }">
                          <b-form-select
                            :state="errors[0] ? false : valid ? true : selectedOwner.language ? true : false"
                            :disabled="!canEditOwnerForm"
                            v-model="selectedOwner.language"
                          >
                            <option value="" disabled>Seleccione Idioma</option>
                            <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                              {{ lang.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider name="nacionalidad" rules="required">
                        <b-form-group label="Nacionalidad*" slot-scope="{ valid, errors }">
                          <b-form-select
                            :state="errors[0] ? false : valid ? true : selectedOwner.nacionality ? true : false"
                            :disabled="!canEditOwnerForm"
                            v-model="selectedOwner.nacionality"
                          >
                            <option disabled value="">Seleccione Nacionalidad</option>
                            <option
                              v-for="nacionalityList in nationalities"
                              :key="nacionalityList.id"
                              :value="nacionalityList.id"
                            >
                              {{ nacionalityList.name }}
                            </option>
                          </b-form-select>
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider name="ciudad">
                        <b-form-group label="Ciudad" slot-scope="{ valid, errors }">
                          <b-form-input
                            class="form-control"
                            type="text"
                            :disabled="!canEditOwnerForm"
                            v-model="selectedOwner.address.city"
                            :state="errors[0] ? false : valid ? true : null"
                            @input="uppercaseText"
                          />
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                      <ValidationProvider  name="código postal">
                        <b-form-group label="Código postal" slot-scope="{ valid, errors }">
                          <b-form-input
                            class="form-control"
                            maxlength="10"
                            @keypress="onlyNumbersLetters"
                            :disabled="!canEditOwnerForm"
                            v-model="selectedOwner.address.zip"
                            :state="errors[0] ? false : valid ? true : null"
                          />
                          <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col md="6">
                  <ValidationProvider name="direccion">
                    <b-form-group label="Direccion" slot-scope="{ valid, errors }">
                      <b-form-textarea
                        class="form-control"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.address.adress"
                        :state="errors[0] ? false : valid ? true : null"
                        rows="4"
                        max-rows="4"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <hr>
              <b-row align-v="center" >
                <b-col md="3">
                  <ValidationProvider name="tipo" rules="" >
                    <b-form-group label="Tipo" slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : selectedOwner.typeOwner != null ? true : false"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.typeOwner"
                        @change="resetDescription"
                      >
                        <option :value="null">Seleccione tipo</option>
                        <option
                          v-for="tipo in ownerType"
                          :key="tipo.id"
                          :value="tipo.id"
                        >
                          {{ tipo.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3" v-if="selectedOwner.typeOwner == 3">
                  <ValidationProvider name="Descripción" rules="">
                    <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="text"
                        :disabled="!canEditOwnerForm"
                        v-model="selectedOwner.typeOwnerDescription"
                        :state="errors[0] ? false : valid ? true : null"
                        @input="uppercaseText"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <div class="float-rigth mt-1">
                    <b-form-checkbox
                      :disabled="!canEditOwnerForm"
                      v-model="selectedOwner.status"
                      :value="true"
                      class="custom-control-success"
                    > Activo
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col md="3">
                  <div class="float-rigth mt-1">
                    <b-form-checkbox
                      :disabled="!canEditOwnerForm"
                      v-model="selectedOwner.canBook"
                      class="custom-control-success"
                    > Puede reservar
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Viviendas" @click="getOwnerHousingInfo">
              <h4 class="pl-1">{{originalSelectedOwner.name}} {{ originalSelectedOwner.lastname }}</h4>
              <CustomersHousing
                ref="CustomersHousingTables"
                :selectedOwner="selectedOwner"
                :isDeletingHouseOwner="isDeletingHouseOwner"
                :isLoadingHousing="isLoadingHousing"
                :isLoadingOwnerHousing="isLoadingOwnerHousing"
                :idHousing="idHousing"
                :newHousingList="newHousingList"
                :housingList="housingList"
                :canEditOwnerForm="canEditOwnerForm"
                :hotels="currentHotels"
                :isLoadingSomething="isLoadingSomething"
                @delete-house="deleteHouse"
                @reset-housing="resetHousing"
                @search-by-filter="searchByFilter"
              />
            </b-tab>
          </b-tabs>
          <hr>
          <b-row>
            <b-col>
              <div class="float-right">
                <span>
                  <b-button
                    v-if="canEditOwnerForm"
                    type="button"
                    @click.prevent="update"
                    class="btn-block"
                    variant="primary"
                    :disabled="invalid || isLoadingSomething"
                  >
                    <b-spinner small v-if="isUpdatingOwner" /> Actualizar
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <b-row v-else>
        <b-col md="12">
          <b-card-body>
            <b-alert variant="danger" show ><div class="alert-body"> Debe de seleccionar un propietario </div></b-alert>
          </b-card-body>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
  <!-- No membership -->
  <b-card v-else >
    <b-col md="12">
      <div class="text-right">
        <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
      </div>
    </b-col>
    <b-card-body class="text-center">
      <div v-if="isLoadingOwner">
        <b-spinner/> <br> Cargando información de propietario
      </div>
      <b-alert variant="danger" show v-else-if="!originalSelectedOwner" >
        <div class="alert-body"> No se ha encontrado un propietario que coincida con este registro </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { idHousing, ownerType } from '@/data/fivesClub'
import { showAlertMessage, sweetAlert, isANumber } from '@/helpers/helpers'
import { namesGender, namesPrefixId } from '@/data/data'
import { createArraysToUpdateCustomers } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { acl } from "@/modules/auth/mixins/acl"

import CustomersHousing from "@/modules/fivesClub/components/catalogs/customers/customerEdition/CustomersHousing";

export default {
  mixins: [utils, acl],
  components: {
    CustomersHousing
  },
  async mounted() {
    await this.init()
  },
  data() {
    return {
      idOwner: this.$route.params.idOwner,
      originalSelectedOwner: null,
      selectedOwner: null,
      idHousing,
      housingList:[],
      newHousingList:[],
      namesGender,
      namesPrefixId,
      isLoadingHousing: false,
      isLoadingOwnerHousing: false,
      isUpdatingOwner: false,
      isLoadingOwner: false,
      isDeletingHouseOwner: false,
      status: true,
      tabIndex: 0,
      photo: "",
      ownerType
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['subCondos', 'nationalities', 'requestOwners' ]),
    ...mapState('auth', ['user']),
    ...mapState("start", ["languages", "hotels"]),
    canEditOwnerForm(){
      return this.can('fivesclub_owners_show_owners_edit_form_editable')
    },
    isLoadingSomething(){
      return (!!this.isLoadingHousing || !!this.isLoadingOwnerHousing || !!this.isUpdatingOwner || !!this.isLoadingOwner || !!this.isDeletingHouseOwner)
    },
    currentHotels(){
      return this.hotels
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['updateOwners', 'fetchOwners', 'fetchHousingOptions', 'deleteHouseOwner', 'checkIfClientExists', 'getInitialContentOwners', 'fetchOwnersHousingData']),
    ...mapMutations('fivesClubCatalogs', ['setSelectedOwner', 'setOwners','setHousing','updateHousingListOwner', 'setOwnerHousing']),
    async init(){
			if(isANumber(this.idOwner)){
        this.isLoadingOwner = true
        await this.getInitialContentOwners({
          hotels: !this.hotels?.length > 0,
          languages: !this.languages?.length > 0,
          nationalities: !this.nationalities?.length > 0,
        }) // aqui pido hotels, languages y nationalities

        const response = await this.fetchOwners({ id: this.idOwner})

        if (response.length > 0){
          this.selectedOwner = structuredClone(response[0])
          this.originalSelectedOwner = structuredClone(response[0])
        }

        this.isLoadingOwner = false
      }
    },
    async update() {
      const tableNewHousing = this.$refs?.CustomersHousingTables?.$refs?.newHousingListForm
      const validHousingData = tableNewHousing ? await tableNewHousing.validate() : true
      if (validHousingData) {
        const dataConfirm = {
          title: "¿Está seguro?",
          message: "¿Seguro de realizar la actualización?",
          submessage: null,
          confirmButton: "Sí, guardar",
          cancelButton: "Cancelar",
          icon: 'success',
        }

        const housingWithOwners = this.newHousingList.filter(house => !!house.state && house.contract).map(house => {
          return {
            contrato: house.contract,
            membresía: house.membershipcode,
            vivienda: house.fullname,
            propietario: house.ownername
          }
        })
        if (housingWithOwners.length > 0) {
          dataConfirm.housingWithOwners = housingWithOwners
          dataConfirm.message = 'Las siguientes viviendas se van a relacionar con el siguiente propietario:'
          dataConfirm.submessage = "¿Seguro de realizar la actualización?"
        }

        const isConfirmed = await this.confirmSaving(dataConfirm)

        const adressesList = {
          adress: this.selectedOwner.address?.adress,
          city: this.selectedOwner.address?.city,
          zip: this.selectedOwner.address?.zip,
        }
        const housingList = createArraysToUpdateCustomers( this.housingList, this.newHousingList, this.selectedOwner.id )
        const payload = {
          idUser: this.user.idUser,
          idOwner: this.selectedOwner.id,
          name: this.selectedOwner.name,
          lastname: this.selectedOwner.lastname,
          email: this.selectedOwner.email,
          phone: this.selectedOwner.phone,
          prefix: this.selectedOwner.prefix,
          gender: this.selectedOwner.gender,
          nacionality: this.selectedOwner.nacionality,
          language: this.selectedOwner.language,
          housing: housingList,
          adresses: adressesList,
          photo: null,
          status: Boolean(this.selectedOwner.status),
          canBook: this.selectedOwner.canBook,
          typeOwner: this.selectedOwner.typeOwner,
          birthdate: Boolean(this.selectedOwner?.birthdate) ? this.selectedOwner?.birthdate : null,
          Description: this.selectedOwner.typeOwnerDescription
        }

        if (isConfirmed) {
          // console.log(payload)
          this.isUpdatingOwner = true
          const respuesta = await this.updateOwners(payload)
          if (respuesta) {
            sweetAlert({
              title: '¡Listo!',
              message: 'El usuario ha sido actualizado exitosamente',
              icon: 'success',
            })
            this.resetHousing()
            await this.init()
            if (this.tabIndex == 1) await this.getOwnerHousingData()
            if (!Boolean(this.selectedOwner.status)) this.goToHome({reloadOwners: true})
          } else {
            this.isUpdatingOwner = false
            return sweetAlert({
              title: 'Hubo un problema al guardar',
              message: 'No se ha podido actualizar el usuario, por favor intentelo más tarde',
              icon: 'warning',
            })
          }
          this.isUpdatingOwner = false
        }
      } else this.tabIndex = 1
    },
    async getOwnerHousingData(){
      this.isLoadingOwnerHousing = true
      const response = await this.fetchOwnersHousingData({ownerId: this.idOwner})
      this.housingList = response.map(house => {
        return {...house, state: true}
      })

      this.isLoadingOwnerHousing = false
    },
    async getOwnerHousingInfo(){
      if (this.housingList.length == 0) await this.getOwnerHousingData()
    },
    async checkIfEmailClientExist(email) {
      //eslint-disable-next-line
      const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (reg.test(email)) {
        const info = await this.checkIfClientExists(email) // updateBlackout in backend
        if (info?.id && this.selectedOwner?.id != info?.id) {
          this.selectedOwner.email = structuredClone(this.originalSelectedOwner.email)
          return await sweetAlert({
            title: 'Correo duplicado',
            message: 'Ya existe una cuenta con este correo asignado',
            icon: 'warning',
          })
        }
      }
    },
    async searchByFilter(data) {
      const {selectedResort, selectedVivienda} = data
      // this.resetHousing()
      if(!selectedResort) return showAlertMessage( 'Seleccione hotel', 'InfoIcon', 'Seleccione un hotel para continuar', 'warning', 4000, 'bottom-right' )
      const payload = {
        resort: selectedResort,
        Number: selectedVivienda,
        CreateOwner: true,
      }
      this.isLoadingHousing = true
      const idxHousing = this.housingList.map( house => house.idHousing) //array de idx de viviendas que tiene el owner
      const listHousing = await this.fetchHousingOptions(payload)
      // console.log( listHousing )
      const viviendasFiltradas = listHousing.filter(({id}) => !idxHousing.includes(id)).map(house => { //filtro las viviendas que sean diferentes al que trae el owner
        return {
          id: house.id,
          percent: house.percent,
          dateIn: house.dateIn,
          dateOut: house.dateOut,
          relation: null,
          currentOwner: Boolean(house.currentOwner),
          status: house.status,
          fullname: house.fullname,
          contract: house.contract,
          membershipcode: house.membershipcode,
          ownername: house.ownername,
          idContract: house.idContract,
        }
      })
      this.newHousingList = this.newHousingList.filter(h=>!!h.state && !viviendasFiltradas.map(v=>v.id).includes(h.id)).concat(viviendasFiltradas)
      // this.setHousing( viviendasFiltradas )
      this.isLoadingHousing = false
    },

    async deleteHouse(house){
      const swalert = {
        title: 'Eliminando vivienda: ' + house.fullname,
        message: '¿Desea eliminar esta vivienda?',
        icon: 'question',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar',
      }

      const {isConfirmed} = await sweetAlert(swalert)

      if (isConfirmed) {
        this.isDeletingHouseOwner = true
        const respuesta = await this.deleteHouseOwner({idOwnerHousing: house.idOwnerHousing})
        if(respuesta){
          const { status, message } = respuesta
          if (status){
            this.resetHousing()
            showAlertMessage('Ok: borrado finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
            await this.getOwnerHousingData()
            this.resetHousing()
          } else showAlertMessage( "Error: proceso finalizado", "InfoIcon", `${message}`, "warning", 4000, "bottom-right" )
        } else showAlertMessage( "Error: proceso finalizado", "InfoIcon", 'Hubo un error al eliminar la vivienda, por favor inténtelo más tarde', "danger", 4000, "bottom-right" )
        this.isDeletingHouseOwner = false
      }
    },
    async confirmSaving(info){
			const {icon, title, message, submessage, confirmButton, cancelButton, housingWithOwners} = info
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', { domProps: { innerHTML: `Actualizando propietario: ${this.originalSelectedOwner.name} ${this.originalSelectedOwner.lastname}`} })
      // More complex structure

      const messageVNode = housingWithOwners?.length > 0 ?
      h('div', { class: ['foobar'] }, [
        h('h4', { class: ['text-center']}, [`${title}`] ),
        h('p', { class: ['text-center mb-1'] }, [`${message}`,]),
        h('b-table', {
          props: {
            items: housingWithOwners,
            // fields: info?.table?.datafields,
            small: true,
            responsive: true,
            showEmpty: true,
            emptyText: "No matching records found",
          },
        }),
        h('p', { class: ['text-center mb-1'] }, [`${submessage}`,]),
      ])
      :
      h('div', { class: ['foobar'] }, [
        h('h4', { class: ['text-center']}, [`${title}`] ),
        h('p', { class: ['text-center mb-1'] }, [`${message}`,]),
      ])

      // We must pass the generated VNodes as arrays
      const modal = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        buttonSize: 'sm',
        centered: true,
        okTitle: confirmButton,
        cancelTitle: cancelButton,
      })

      return modal
    },
    uppercaseText(){
      this.selectedOwner.name = this.selectedOwner.name.toUpperCase()
      this.selectedOwner.lastname = this.selectedOwner.lastname.toUpperCase()
      this.selectedOwner.address.city = this.selectedOwner.address.city.toUpperCase()
      this.selectedOwner.address.adress = this.selectedOwner.address.adress.toUpperCase()
    },
    resetDescription(){
      if (this.selectedOwner.typeOwner == 3) this.selectedOwner.typeOwnerDescription = structuredClone(this.originalSelectedOwner.typeOwnerDescription)
      else this.selectedOwner.typeOwnerDescription = null
    },
    goToHome(params){
      this.$router.push({ name: 'customers', params })
    },
    resetHousing() {
      this.newHousingList = []
    },
    resetAllData(){
      this.resetHousing()
      this.updateHousingListOwner([])
      this.setSelectedOwner(null)
    }
  },
};
</script>

<style>
.custom-input-margin{
  margin: 0.34rem 0;
}
</style>